import Vue from 'vue';
import VueMaterial from 'vue-material';
import moment from 'moment';
import VueQuillEditor from 'vue-quill-editor';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

Vue.use(VueQuillEditor /* { default global options } */);
Vue.use(VueMaterial);

Vue.prototype.moment = moment;
Vue.config.productionTip = false;
Vue.material.locale.dateFormat = 'dd/MM/yyyy';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
