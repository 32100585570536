import Vue from 'vue';
import VueRouter from 'vue-router';
import { verify } from '@/assets/js/verifyAuth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/dashboard',
    name: 'Início',
    beforeEnter: verify,
    redirect: { name: 'Server' },
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: 'server',
        name: 'Server',
        component: () => import('../views/dashboard/Server.vue'),
      },
      {
        path: 'donates',
        name: 'Donates',
        component: () => import('../views/dashboard/Donates.vue'),
      },
      {
        path: 'characters',
        name: 'Characters',
        component: () => import('../views/dashboard/Characters.vue'),
      },
      {
        path: 'accounts',
        name: 'Accounts',
        component: () => import('../views/dashboard/Accounts.vue'),
      },
      {
        path: 'events',
        name: 'Events',
        component: () => import('../views/dashboard/Events.vue'),
      },
      {
        path: 'onlines',
        name: 'Onlines',
        component: () => import('../views/dashboard/Onlines.vue'),
      },
      {
        path: 'tickets',
        name: 'Tickets',
        component: () => import('../views/dashboard/Tickets.vue'),
      },
      {
        path: 'ticket/:id',
        name: 'Ticket',
        component: () => import('../views/dashboard/Ticket.vue'),
      },
      {
        path: 'invalid-names',
        name: 'Invalid Names',
        component: () => import('../views/dashboard/InvalidNames.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login') {
    verify(to, from, next);
  } else {
    next();
  }
});

export default router;
