import jwtDecode from 'jwt-decode';
import moment from 'moment';

export const verify = (to, from, next) => { // eslint-disable-line
  const vuex = JSON.parse(localStorage.getItem('vuex'));
  const token = vuex?.user?.token;

  if (token) {
    const decodedToken = jwtDecode(token.replace('Bearer ', ''));

    if (decodedToken.exp >= moment().format('X') && decodedToken.type === 5) {
      next();
    } else {
      window.location.href = '/';
    }
  } else {
    window.location.href = '/';
  }
};
