<template>
  <section class="app">
    <md-snackbar
      :md-position="getSnackBar.position"
      :md-duration="getSnackBar.duration"
      :md-active.sync="getSnackBar.showSnackbar"
      md-persistent>
      <span>{{ getSnackBar.message }}</span>
      <md-button
        class="md-primary"
        @click="dismissSnackBar()">
        {{ getSnackBar.button }}
      </md-button>
    </md-snackbar>
    <router-view></router-view>
  </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getSnackBar']),
  },
  methods: {
    ...mapActions(['setSnackBar']),
    dismissSnackBar() {
      this.setSnackBar({});
    },
  },
};
</script>
