import Vue from 'vue';
import Vuex from 'vuex';
import createPersistState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistState()],
  state: {
    darkTheme: false,
    snackBar: {},
    user: {},
  },
  getters: {
    getDarkTheme(state) {
      return state.darkTheme;
    },
    getSnackBar(state) {
      return state.snackBar;
    },
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    setDarkTheme(state, payload) {
      state.darkTheme = payload;
    },
    setSnackBar(state, payload) {
      state.snackBar = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    setDarkTheme({ commit }, payload) {
      commit('setDarkTheme', payload);
    },
    setSnackBar({ commit }, payload) {
      commit('setSnackBar', payload);
    },
    setUser({ commit }, payload) {
      commit('setUser', payload);
    },
  },
  modules: {
  },
});
